import React from "react"
import { Link } from "gatsby"
import axios from "axios";
import Img from "gatsby-image"
import cookie from "../lib/cookie";
import Layout from "../components/layout"
import VerticalAlign from "../components/verticalAlign"
import AccountHeader from "../components/accountHeader"
import AccountContainer from "../components/accountContainer"
import AccountSecondary from "../components/accountSecondary"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import theme from "../theme"

const Styles = styled.div`
  .my-item {
    display: grid;
    grid-template-columns: 50px calc(100% - 94px) 20px;
    grid-gap: 12px;
    padding: 16px 18px;
    padding-right: 4px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;

    .title {
      font-size: 18px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 19px;
    }

    // &.active-link {
    //   background: ${ theme.yellow };
    //   color: white;

    //   .title, .subtitle {
    //     color: white;
    //   }

    //   svg {
    //     path: white;
    //   }
    // }
  }
  .my-image {
    span {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: #ccc;
      display: block;
    }

    .gatsby-image-wrapper {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: #ccc;
      display: block;
    }
  }

  .my-icons {
    float: right;

    svg {
      width: 22px;
      margin-left: 14px;
      cursor: pointer;
    }
  }

  
`

export default class extends React.Component {
  constructor(props) {
    super()
    this.state = {
      links: []
    }
    /*this.state.links = [
      {
        id: "noad@stokes.com",
        name: "Noah Stokes",
        first: "Noah",
        img: props.data.noah.childImageSharp.fluid,
        companyLogo: props.data.noahLogo.childImageSharp.fluid,
        company: "Last Gard",
        title: "Creative Director",
        links: [
          {
            title: "Linkedin",
            url: "www.linkedin.com/in/noahstokes",
            img: props.data.linkedin.childImageSharp.fluid,
          },

          {
            title: "Twitter",
            url: "@noahstokes",
            img: props.data.twitter.childImageSharp.fluid,
          },

          {
            title: "Facebook",
            url: "www.facebook.com/people/stokesn",
            img: props.data.facebook.childImageSharp.fluid,
          },

          {
            title: "Instagram",
            url: "@noahs",
            img: props.data.instagram.childImageSharp.fluid,
          },

          {
            title: "Skype",
            url: "noah.stokes",
            img: props.data.skype.childImageSharp.fluid,
          },
        ],
        bio:
          "Veteran designer with over 17 years experience. Passionate about product design, user experience, and building great products–without compromise. Noah has a diverse background in client services consulting, with over a decade focusing on design, development, and project management.",
      },

      {
        id: "arthur@vandelay.com",
        name: "Arthur Vandelay",
        first: "Arthur",
        img: props.data.vandelay.childImageSharp.fluid,
        companyLogo: props.data.vandelayLogo.childImageSharp.fluid,
        company: "District of New York",
        title: "Judge",
        links: [
          {
            title: "Linkedin",
            url: "www.linkedin.com/in/arthurvandelay",
            img: props.data.linkedin.childImageSharp.fluid,
          },

          {
            title: "Facebook",
            url: "www.facebook.com/people/arthurvandelay",
            img: props.data.facebook.childImageSharp.fluid,
          },

          {
            title: "Twitter",
            url: "@arthurvandelay",
            img: props.data.twitter.childImageSharp.fluid,
          },

          {
            title: "Skype",
            url: "arthur.vandelay",
            img: props.data.skype.childImageSharp.fluid,
          },

          

          {
            title: "Instagram",
            url: "@arthurvandelay",
            img: props.data.instagram.childImageSharp.fluid,
          },

          
        ],
        bio:
          "Veteran judge with over 67 years experience. Passionate about law, society, and conducting business. Arthur...",
      },
    ]*/
  }

  image(el) {
    const { data } = this.props;
    if (el.profileImage) {
      return (
        <div className="my-image">
          <Img fluid={data[el.profileImage].childImageSharp.fluid} alt={el.name} />
        </div>
      )
    } else {
      return (
        <div className="my-image">
          <span></span>
        </div>
      )
    }
  }

  setCurrent(e) {

    let activeLink = document.querySelectorAll(".active-link");

    if (activeLink[0]) {
      activeLink[0].classList.toggle("active-link");
    }
    e.currentTarget.classList.toggle("active-link")

    
    let contact = this.state.links.filter(el => {
      return el.id == e.currentTarget.dataset.id
    })[0]

    this.setState({
      current: <Contact contact={contact} parent={ this } imageData={this.props.data} />, active: true,
    })
  }

  componentDidMount () {
    if (typeof window !== undefined ) {
      window.onresize = this.handleResize.bind(this)
    }
    this.getContacts();
  }

  async getContacts(){
    const user = cookie.get('user') ? JSON.parse(cookie.get('user')) : '';
    if(!user){
      return;
    }
    const contacts = [];
    const seen = []; // temp for demo, probably implement something backend for production
    const url = `https://us-central1-lastcard-50e67.cloudfunctions.net/lastcard/contacts?user=${user.id}`;
    try {

        let res = await axios.get(url);
        for(let key in res.data.contacts){
          let value = res.data.contacts[key];
          if(!seen.includes(value.contactId)){
            seen.push(value.contactId); // update seen
            const profile = res.data.profiles[value.contactId];
            contacts.push(profile);
          }
        }

    } catch (err) {
        console.error(err);
    }
    this.setState({ links: contacts });
  }

  handleResize ( e ) {
    if (window.innerWidth < 700) {
      this.setState({
        trigger: false
      })
    }
  }

  link(el) {
    return (
      <div
        className="my-item"
        data-id={el.id}
        onClick={this.setCurrent.bind(this)}
        key={el.id}
      >
        {this.image(el)}
        <div>
          <div className="title">{el.name}</div>

          <div className="subtitle">{el.position}</div>
        </div>

        <div>
          <VerticalAlign>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="20"
              viewBox="0 0 8 20"
              fill="none"
            >
              <rect
                x="1.55359"
                y="19.5166"
                width="1.79394"
                height="11.6606"
                rx="0.896969"
                transform="rotate(-150 1.55359 19.5166)"
                fill="#C4C4C4"
              />
              <rect
                x="0.00012207"
                y="0.896973"
                width="1.79394"
                height="11.6606"
                rx="0.896969"
                transform="rotate(-30 0.00012207 0.896973)"
                fill="#C4C4C4"
              />
            </svg>
          </VerticalAlign>
        </div>
      </div>
    )
  }

  trigger () {
    this.setState({ trigger: !this.state.trigger})
  }

  reset () {
    this.setState({
      active: false,
      current: ""
    })
  }

  render() {

    let active = this.state.active ? "active" : "";

    return (
      <Layout>
        <SEO title="Contacts" />
        <Styles>
          <AccountHeader page="my-contacts" />

          <AccountSecondary >


            <div className="my-title">
              Contacts
              <div className="my-icons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="26"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15.7632 15.6413C15.5434 15.8643 14.9344 16.4823 15.1196 16.6703C15.3048 16.8582 15.5942 17.1518 15.8324 17.3935L21.4445 23.0887C21.6826 23.3304 21.972 23.6241 22.1572 23.812C22.3424 24 22.9514 23.382 23.1712 23.1589C23.391 22.9359 24 22.3179 23.8147 22.13C23.6295 21.9421 23.3402 21.6484 23.102 21.4067L17.4899 15.7115C17.2517 15.4698 16.9623 15.1761 16.7771 14.9882C16.5919 14.8002 15.983 15.4182 15.7632 15.6413Z"
                    fill="#E3C36F"
                  />
                  <path
                    d="M18.7485 9.96713C18.7485 14.99 14.7395 19.0393 9.82176 19.0393C4.904 19.0393 0.895 14.99 0.895 9.96713C0.895 4.94429 4.904 0.895 9.82176 0.895C14.7395 0.895 18.7485 4.94429 18.7485 9.96713Z"
                    stroke="#E3C36F"
                    strokeWidth="1.79"
                  />
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  
                >
                  <path
                    d="M0 0.985752C0 1.24719 0 1.97156 0.550711 1.97154C1.10142 1.97156 1.96196 1.9715 2.67012 1.9715H19.3583C20.0665 1.9715 20.927 1.9715 21.4777 1.9715C22.0285 1.9715 22.0285 1.24719 22.0285 0.985752C22.0285 0.724315 22.0285 1.24648e-06 21.4777 3.79906e-05C20.927 4.93104e-05 20.0665 1.24648e-06 19.3583 1.24648e-06H2.67012C1.96196 1.24648e-06 1.10142 -1.13173e-05 0.550711 4.333e-05C0 -1.13173e-05 0 0.724315 0 0.985752Z"
                    fill="#E3C36F"
                  />
                  <path
                    d="M3.30432 12C3.30432 12.2615 3.30432 12.9858 3.66779 12.9858C4.03126 12.9858 4.59921 12.9858 5.0666 12.9858H16.0808C16.5482 12.9858 17.1162 12.9858 17.4796 12.9858C17.8431 12.9858 17.8431 12.2615 17.8431 12C17.8431 11.7386 17.8431 11.0143 17.4796 11.0143C17.1162 11.0143 16.5482 11.0143 16.0808 11.0143H5.0666C4.59921 11.0143 4.03126 11.0143 3.66779 11.0143C3.30432 11.0143 3.30432 11.7386 3.30432 12Z"
                    fill="#E3C36F"
                  />
                  <path
                    d="M7.70996 23.0142C7.70996 23.2756 7.70996 24 7.8917 24C8.07343 24 8.35741 23.9999 8.5911 23.9999H14.0982C14.3319 23.9999 14.6159 23.9999 14.7976 23.9999C14.9794 23.9999 14.9794 23.2756 14.9794 23.0142C14.9794 22.7528 14.9794 22.0284 14.7976 22.0285C14.6159 22.0285 14.3319 22.0284 14.0982 22.0284H8.5911C8.35741 22.0284 8.07343 22.0284 7.8917 22.0285C7.70996 22.0284 7.70996 22.7528 7.70996 23.0142Z"
                    fill="#E3C36F"
                  />
                </svg>
            </div>
            </div>

            <div className="my-links">
              {this.state.links.map(this.link.bind(this))}
            </div>
          </AccountSecondary>

          <AccountContainer  className={ active }>
            {this.state.current}
          </AccountContainer>
        </Styles>
      </Layout>
    )
  }
}

export class Contact extends React.Component {

  link ([key, value]) {
    return (
      <div className="my-link" key={key}>
        <div>
          {this.props.imageData[key] && <Img fluid={ this.props.imageData[key].childImageSharp.fluid } />}
        </div>

        <div>
          <div className="link-title">
          {
            key
          }
          </div>

          <div className="link-subtitle">
          {
            value
          }
          </div>
        </div>
      </div>
    )
  }

  exit() {
    this.props.parent.reset()
  }
  

  render() {
    if (typeof window !== undefined) {
      // window.scroll/
    }

    const { imageData, parent } = this.props; 

    return (
      <div className="white-background">
        <div className="my-main">
          <div className="title">
            {console.log(parent)}
            {parent && (
              <div className="closer" onClick={this.exit.bind(this)}>
              <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 30 30"
                    fill="none"
                  >
                    <path
                      d="M8.18698 7.6708C8.0679 7.78989 7.73796 8.11983 8.09152 8.47338C8.44506 8.82694 8.99755 9.37937 9.45218 9.83401L20.1659 20.5477C20.6206 21.0024 21.173 21.5548 21.5266 21.9084C21.8801 22.2619 22.21 21.932 22.3291 21.8129C22.4482 21.6939 22.7781 21.3639 22.4246 21.0104C22.071 20.6569 21.5186 20.1044 21.0639 19.6497L10.3502 8.936C9.89555 8.48137 9.3431 7.9289 8.98952 7.57537C8.63599 7.2218 8.30607 7.55172 8.18698 7.6708Z"
                      fill="#E3C36F"
                    />
                    <path
                      d="M7.67077 21.813C7.78986 21.932 8.1198 22.262 8.47335 21.9084C8.82691 21.5549 9.37934 21.0024 9.83398 20.5478L20.5477 9.83402C21.0023 9.37939 21.5548 8.82693 21.9084 8.47337C22.2619 8.11982 21.932 7.7899 21.8129 7.67082C21.6938 7.55174 21.3639 7.22182 21.0104 7.57539C20.6568 7.92895 20.1043 8.48138 19.6497 8.93602L8.93597 19.6498C8.48134 20.1044 7.92887 20.6568 7.57534 21.0104C7.22177 21.3639 7.55169 21.6939 7.67077 21.813Z"
                      fill="#E3C36F"
                    />
                  </svg>
                </div>
            
            )}
          
          </div>
          <div>
            {this.props.contact.profileImage && <Img
              className="my-image"
              fluid={imageData[this.props.contact.profileImage].childImageSharp.fluid}
              alt={this.props.contact.name}
            />}
          </div>

          <div className="my-name">{this.props.contact.name}</div>

          <div className="my-button button is-warning">Save Contact</div>

          <hr></hr>
          <div className="">
            <div className="company-title">
              <div className="my-title">{this.props.contact.position}</div>

              <div className="company">{this.props.contact.organization}</div>
            </div>

            <div className="company-logo">
              {this.props.contact.companyLogo && <Img
                fluid={imageData[this.props.contact.companyLogo].childImageSharp.fluid}
                alt={this.props.contact.organization}
              />}
            </div>
          </div>

          <hr></hr>

          {this.props.contact.bio && (
            <>
              <div className="my-title" style={{marginBottom: 12}}>About {this.props.contact.name}</div>
              <div className="my-secondary">{this.props.contact.bio}</div>
            </>
          )}

          <hr></hr>

          {this.props.contact.links && (
            <>
              <div className="my-title" style={{marginBottom: 12}}>Links</div>
              <div className="links">
                  {
                    Object.entries(this.props.contact.links).map( this.link.bind(this) ) 
                  }

              </div>
            </>
          )}

        </div>

        
      </div>
    )
  }
}

export const query = graphql`
  {

    taqi: file(relativePath: { eq: "Taqi.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    vish: file(relativePath: { eq: "Vish.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pavan: file(relativePath: { eq: "Pavan.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    noah: file(relativePath: { eq: "noah.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    noahLogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    vandelay: file(relativePath: { eq: "vandelay.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    vandelayLogo: file(relativePath: { eq: "vandelay-logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    linkedin: file(relativePath: { eq: "linkedin.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    facebook: file(relativePath: { eq: "facebook.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    twitter: file(relativePath: { eq: "twitter.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    skype: file(relativePath: { eq: "skype.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
